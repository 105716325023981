"use strict";

var Cell     = require('../cell'),
    dijkstra = require('./dijkstra'),
    _        = require('underscore');

// Find the shortest path from origin to one of the possible destinations
var findPathTo = function (origin, destinations, playfield, adjaceneyFunction /* optional */) {
  var context = dijkstra(origin, destinations, playfield, adjaceneyFunction);
  if (context.destination)
    return buildRoute(context.cameFrom, context.destination, playfield);
  throw new Error("No route found from " + origin.id + " to [" + _.pluck(destinations, "id") + "] !");
}

// Find the shortest path length for every reachable square from origin
var findPathDistancesFrom = function (origin, playfield, adjaceneyFunction /* optional */) {
  var context = dijkstra(origin, [], playfield, adjaceneyFunction);
  return context.gScores;
}

//
// This is not expected to be called from outside of this module (and it is not
// exported)
var dijkstra = function (origin, destinations, playfield, adjaceneyFunction /* optional */) {
  var seenSet = {},
      cameFrom = {},
      gScores = {},
      // The heurisitic part, which we dont need in Dijkstra. It's just gScore
      //fScores = {},
      potential = [],
      current,
      ret = { gScores: gScores, cameFrom: cameFrom };

  if (adjaceneyFunction === undefined)
    adjaceneyFunction = adjacentWetCells;

  destinations = _.pluck(destinations, "id");
  origin = origin.id;

  potential = [origin];
  gScores[origin] = 0;

  var count = 0;

  while (potential.length > 0) {
    current = potential.pop();

    if (_.include(destinations, current)) {
      ret.destination = current;
      return ret;
    }

    if (!seenSet[current])
      seenSet[current] = true;

    // For each adjacent to current:
    _.forEach(adjaceneyFunction(current, playfield), function(adjacent) {
      // The '1' value here is the cost of moving from one tile to the next
      // (the 'edge weight' in A*/Dijkstra)

      if (seenSet[adjacent])
        return;

      var gScore = gScores[current] + 1;

      if (seenSet[adjacent] && gScore >= gScores[adjacent]) {
        // We are coming in with a longer path than we've already found
        // Break out of the forEach(adjacentCells)
        return;
      }

      if (!_.include(potential, adjacent) || gScore < gScores[adjacent]) {
        cameFrom[adjacent] = current;
        gScores[adjacent] = gScore;
        potential.push(adjacent);
        potential = _.sortBy(potential, function(what) {
          return -gScores[what];
        });
      }
    });
  }
  return ret;
}


// The default adjaceneyFunction for path finding - only include wet cells
var adjacentWetCells = function (current, playfield) {
  var d, cell,
      coords = Cell.idToCoords(current),
      x = coords.x,
      y = coords.y,
      results = [];

  for (d = -1; d <= 1; d += 2) {
    y = coords.y + d;
    cell = playfield.getCell(x, y);
    if (cell && (cell.isWet() || cell.isSink()))
      results.push(cell.id);
  }

  y = coords.y;
  for (d = -1; d <= 1; d += 2) {
    x = coords.x + d;
    cell = playfield.getCell(x, y);
    if (cell && (cell.isWet() || cell.isSink()))
      results.push(cell.id);
  }

  return results;
}

var buildRoute = function (cameFrom, destination, playfield) {
  var result = [];

  while (_.has(cameFrom, destination)) {
    result.unshift(playfield.getCellByID(destination));
    destination = cameFrom[destination];
  }

  return result;
}

dijkstra.findPathTo = findPathTo;
dijkstra.findPathDistancesFrom = findPathDistancesFrom;

module.exports = dijkstra;
