'use strict';

var _ = require('underscore'),
    Cell = require('./cell');

function Playfield(cellArray, properties) {
  var y, x;

  this.width = cellArray[0].length;
  this.height = cellArray.length;
  this._cells = {};
  this.createdBy = Playfield.INITIAL_LAYOUT;
  this.version = 0;
  if (_.has(properties, "title")) {
    this.title = properties.title;
  }
  if (_.has(properties, "description")) {
    this.description = properties.description;
  }

  for (y = 0; y < cellArray.length; y++) {
    for (x = 0; x < cellArray[y].length; x++) {
      if (cellArray[y][x] !== undefined) {
        this._cells[ cellArray[y][x].id ] = cellArray[y][x];
      }
    }
  }

  if (_.has(properties, "rain")) {
    this.rain = properties.rain;
  }
}

// Helper method to take a plain dictionary dump (from a game state in the
// browser for instance) and turn it into a full playfield with Cell objects
Playfield.fromDict = function(dict) {
  var playfield = new Playfield([[]]);
  playfield.width = dict.width;
  playfield.height = dict.height;
  playfield.createdBy = Playfield.INITIAL_LAYOUT;

  _.forEach(_.values(dict._cells), function (cellHash) {
    var cell = new Cell(cellHash);
    playfield._cells[cell.id] = cell;
  })

  return playfield;
}

// What move/action caused this playfield to be created
Playfield.INITIAL_LAYOUT = "initial";
Playfield.WATER_SOURCE = "water_source";
Playfield.WATER_FLOW = "water_flow";
Playfield.SINK_EMPTY = "sink_empty"; // I'm not sure this should be a state!
Playfield.HUMAN_MOVE = "human_move";

Playfield.prototype.clone = function () {
  var key, clone;
  clone = new Playfield([[]]);
  clone.width = this.width;
  clone.height = this.height;
  clone.version = this.version + 1;
  for (key in this._cells) {
    if (this._cells.hasOwnProperty(key)) {
      clone._cells[key] = this._cells[key].clone();
    }
  }
  clone.rain = _.map(this.rain, _.clone)
  return clone;
};

Playfield.prototype.getCell = function (x, y) {
  return this._cells[x + "," + y];
};

Playfield.prototype.addCell = function (cell) {
  this._cells[cell.id] = cell;
};

Playfield.prototype.getCellByID = function (id) {
  return this._cells[id];
};

Playfield.prototype.getCellsByTags = function (tags) {
  var foundCells = [];
  var cell;

  for (var id in this._cells) {
    cell = this._cells[id];
    if (tags.indexOf(cell.tag) >= 0) {
      foundCells.push(cell);
    }
  }
  return foundCells;
}

Playfield.prototype.getAdjacentCells = function (x, y, diagonal) {
  if (diagonal === undefined)
    diagonal = true;

  var coords = [
    {y: y + 1, x: x},
    {y: y, x: x + 1},
    {y: y, x: x - 1},
    {y: y - 1, x: x},
  ];

  if (diagonal) {
    coords.push({y: y + 1, x: x + 1});
    coords.push({y: y + 1, x: x - 1});
    coords.push({y: y - 1, x: x + 1});
    coords.push({y: y - 1, x: x - 1});
  }

  return _.reject(_.map(coords, function (coord) {
    return this.getCell(coord.x, coord.y);
  }, this), _.isEmpty);
};

Playfield.prototype.allCells = function () {
  return _.values(this._cells);
};

Playfield.prototype.wetCells = function () {
  return _.filter(this.allCells(), function(c) { return c.isWet(); });
};

Playfield.prototype.sinkCells = function () {
  return _.filter(this.allCells(), function (c) { return c.isSink(); });
};

module.exports = Playfield;
