'use strict';

var EventHandler = require('./eventhandler'),
    _            = require('underscore');

function LevelCollection(levels, completedLevels) {
  this._events = new EventHandler();
  this.levels = _.map(levels, function (level, index) {
    return _.extend({}, level, {
      playable: (index == 0),
      complete: false
    });
  });

  _.forEach(completedLevels, function (levelPath) {
    this.complete(levelPath);
  }, this);
}

LevelCollection.prototype.on = function (eventName, handler) {
  this._events.subscribe(eventName, handler);
};

LevelCollection.prototype.complete = function (levelPath) {
  var levelIndex = this._indexOf(levelPath),
      level = this.levels[levelIndex],
      nextLevel = this.levels[levelIndex + 1];

  if (!level.playable) {
    return;
  }

  level.complete = true;
  this._events.emit("level-updated", { levelPath: level.path });

  if (nextLevel) {
    nextLevel.playable = true;
    this._events.emit("level-updated", { levelPath: nextLevel.path });
  }
};

LevelCollection.prototype.getLevelByPath = function (levelPath) {
  return this.levels[this._indexOf(levelPath)];
};

LevelCollection.prototype.getNextLevel = function (levelPath) {
  return this.levels[this._indexOf(levelPath) + 1];
};

LevelCollection.prototype.completedLevelPaths = function () {
  var completedLevels = _.filter(this.levels, function (level) {
    return level.complete;
  });

  return _.map(completedLevels, function (level) { return level.path });
};

LevelCollection.prototype._indexOf = function (levelPath) {
  var i;
  for (i = 0; i < this.levels.length; i += 1) {
    if (this.levels[i].path === levelPath) {
      return i;
    }
  }
};

module.exports = LevelCollection;
