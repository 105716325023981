"use strict";

var Cell = require('./cell'),
    _    = require('underscore');

var levelfile = {
  DIRECTIONS: {
    '_':  null,
    '^':  Cell.NORTH,
    '>':  Cell.EAST,
    'V':  Cell.SOUTH,
    '<':  Cell.WEST
  },

  TAGS: {
    'H':  'home',
    'T':  'tree',
    'W':  'water-wheel',
    '+':  'source',
    '-':  'sink'
  },

  DELIMITER: '/',

  parse: function(levelData) {
    var level = { cells: [], properties: [] },
        line, parts;

    var lines = _.filter(levelData.split(/\r?\n/), this._keepLine, this);

    while ( lines.length > 0 && lines[0].indexOf("=") != -1) {
      line = lines.shift();
      parts = line.split("=");
      if (parts[0] == "rain")
      {
        level.properties.rain = level.properties.rain || [];
        level.properties.rain.push(this._parseRainString(parts[1]));
      } else {
        level.properties[ parts[0] ] = parts[1];
      }
    }

    level.cells =  _.map(lines, this._parseCellLine, this);
    return level;
  },

  _keepLine: function(line) {
    return !/^ *(#.*)?$/.test(line);
  },

  REQUIRED_RAIN_PROPERTIES: [ "x", "y", "when", "turns", "flowRate" ],

  _parseRainString: function (rainString) {
    // Transform from a string like "x:0 y:1 when:3" to an object like {x:0, y:1, when:3}
    var pairs = _.map(rainString.split(/ +/), function (p) { return p.split(/: */); }),
        rainInfo = _.object(pairs);

    // Lets make sure we have all the right properties set, and that we turn them into numbers
    _.forEach(this.REQUIRED_RAIN_PROPERTIES, function (key) {
      if (!_.has(rainInfo, key)) {
        throw new Error("Rain info '" + rainLine + "' is missing the key " + key);
      }
      var val = parseInt(rainInfo[key], 10);

      if (_.isNaN(val)) {
        throw new Error("Rain info '" + rainLine + "' has non-numeric value for " + key + ":" + rainInfo[key]);
      }
      rainInfo[key] = val;
    });

    return rainInfo;
  },

  _parseCellLine: function(line, y) {
    return _.map(line.match(/[^ ]+/g), function(cell, x) {
      var parts = cell.split(this.DELIMITER);

      // Ignore tiles with no tag or an underscore (empty) tag
      if (parts[2] === undefined || parts[2] === "_") return;

      // The first character of the tag is parsed to get the tag type,
      // the remainder (if any) fill special values
      var tag = this.TAGS[parts[2][0]];
      var dir = this.DIRECTIONS[parts[3]];
      var cell = new Cell({
        elevation: parseInt(parts[0], 10),
        depth:     parseInt(parts[1], 10),
        tag:       tag,
        direction: _.isUndefined(dir) ? null : dir,
        x:         x,
        y:         y
      });
      if (tag == "source" || tag == "sink") {
        cell.flowRate = parseInt(parts[2].substr(1));
      }
      return cell;
    }, this);
  }
};

module.exports = levelfile;
