'use strict';

function EventHandler() {
  this._handlers = {};
}

EventHandler.prototype.subscribe = function (eventName, handler) {
  this._handlers[eventName] = this._handlers[eventName] || [];
  this._handlers[eventName].push(handler);
}

EventHandler.prototype.emit = function (eventName, eventArgument) {
  var handlers = this._handlers[eventName];
  if (handlers) {
    for (var i = 0; i < handlers.length; i += 1) {
      handlers[i](eventArgument);
    }
  }
}

module.exports = EventHandler;
