'use strict';

var Backbone        = require('backbone'),
    _               = require('underscore'),
    $               = require('jquery'),
    BrowserUI       = require('../browserui'),
    Debugger        = require('./debugger'),
    Game            = require('../game'),
    Playfield       = require('../playfield'),
    LevelCollection = require('../levelcollection'),
    levelfile       = require('../levelfile'),
    pressure        = require('../algorithms/pressure');

var MildPeril = {
  boot: function(settings) {
    this._settings = settings;
    this._setupRouter();
    this._loadLevels();
    this._bindGameControlEvents();
  },

  _setupRouter: function() {
    var router = new Backbone.Router(),
        self   = this;

    router.bind('route', function () {
      var url = Backbone.history.getFragment();
      if (url.charAt(0) != '/') { url = '/' + url; }
      ga('send', 'pageview', url);
    });

    router.route('levels', function () {
      self._showLevelIndex();
    });

    router.route('about', function () {
      self._showAbout();
    });

    router.route('levels/:group/:levelPath', function(group, levelPath) {
      self._showGame(group + '/' + levelPath);
    });

    Backbone.history.start();
  },

  _showLevelIndex: function () {
    this._showElementsFor("level-index");
  },

  _showAbout: function () {
    this._showElementsFor("about");
  },

  _showGame: function(levelPath) {
    this._showElementsFor("game");

    var url = this._expandUrl(this._settings.levelDataUrl, {levelPath: levelPath});
    $.get(url).then(function(response) {
      var map = levelfile.parse(response);
      var playfield = new Playfield(map.cells, map.properties);

      MildPeril._currentLevel = levelPath;
      MildPeril.game = new Game(playfield, pressure);
      MildPeril.game.on("game-over", function (event) {
        if (event.win) {
          MildPeril._levelCollection.complete(levelPath);
        }
      });
      MildPeril.ui = new BrowserUI(MildPeril.game, "#playfield");
      if ( window.location.hostname === "localhost" ) {
        MildPeril.debug = new Debugger("#debugger", MildPeril.game, MildPeril.ui);
      }
      MildPeril.ui.build();
    }, null, this);
  },

  _showElementsFor: function (page) {
    $("body").attr("class", page);
    $("[data-show-on]").hide();
    $("[data-show-on='" + page + "']").show();
  },

  _expandUrl: function(template, params) {
    return template.replace(/\{(.*?)\}/g, function(match, name) {
      return params[name];
    });
  },

  _loadLevels: function() {
    var loadLevels = $.getJSON(this._settings.levelManifestUrl),
        self = this;

    loadLevels.then(function(response) {
      var levels = _.flatten(_.map(response.sections, function (s) { return s.levels; }));
      self._levelCollection = new LevelCollection(levels, self._loadCompletedLevels());
      self._levelCollection.on("level-updated", function (event) {
        self._updateMenuItem(event.levelPath);
        self._saveCompletedLevels();
      });
      self._buildLevelMenu(response.sections);
    });
  },

  _buildLevelMenu: function (sections) {
    this._levelMenuItems = {};
    var start = 1;
    _.forEach(sections, function (section) {
      $("<h2></h2>").text(section.name).appendTo("#level-menu");
      var list = $("<ol></ol>").appendTo("#level-menu").attr("start", start);

      _.forEach(section.levels, function (level) {
        this._levelMenuItems[level.path] = $('<li></li>').appendTo(list);
        this._updateMenuItem(level.path);
      }, this);

      start += section.levels.length;
    }, this);
  },

  _updateMenuItem: function (levelPath) {
    var liEle = this._levelMenuItems[levelPath];
    var level = this._levelCollection.getLevelByPath(levelPath);
    var displayName = level.title || level.path;

    liEle.toggleClass("complete", level.complete);

    if (level.playable) {
      var link = $('<a href="#"></a>');
      link.text(displayName);
      link.click(function() {
        Backbone.history.navigate('levels/' + level.path, {trigger: true});
        return false;
      });

      liEle.html('').append(link);
    } else {
      liEle.text(displayName);
    }
  },

  _saveCompletedLevels: function () {
    localStorage["completed-levels"] = this._levelCollection.completedLevelPaths().join(",");
  },

  _loadCompletedLevels: function () {
    var completedLevelsString = localStorage["completed-levels"];
    if (completedLevelsString) {
      return completedLevelsString.split(",");
    } else {
      return [];
    }
  },

  _bindGameControlEvents: function () {
    var self = this;
    $("#restart-level, #game-status .restart-level").click(function () {
      self.ui.restart();
      return false;
    });
    $("#skip-turn").click(function () {
      self.ui.skipTurn();
      return false;
    });
    $("#game-status .next-level").click(function () {
      Backbone.history.navigate(self._nextLevelPath(), {trigger: true});
      return false;
    });
  },

  _nextLevelPath: function () {
    var nextLevel = this._levelCollection.getNextLevel(this._currentLevel);
    if (nextLevel) {
      return 'levels/' + nextLevel.path;
    } else {
      return 'levels';
    }
  }
};

if (typeof window !== "undefined")
{
  window.MildPeril = MildPeril;
  window.dumpLevelJSON = function(ver) {
    return MildPeril.game.levelStateToJSON(ver)
  }
}

module.exports = MildPeril;
