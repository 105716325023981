"use strict";

/*

Main game class that controls the loops of actions and user input.

*/

var EventHandler = require('./eventhandler'),
    Playfield    = require('./playfield'),
    _            = require('underscore');

function Game(playfield, waterFlowAlgorithm) {
  this._playfields = [];
  this._numberOfWaterTurns = Game.NUMBER_OF_WATER_TURNS;
  this._numberOfEndGameWaterPhases = Game.NUMBER_OF_END_GAME_WATER_PHASES;
  this._unfloodableTags = Game.UNFLOODABLE_TAGS;
  this._waterFlowAlgorithm = waterFlowAlgorithm;
  this._events = new EventHandler();
  this._gameOver = false;

  this._setCurrentPlayfield(playfield);
};

Game.NUMBER_OF_WATER_TURNS = 3;

Game.WIN_CHECK_ITERATIONS = 100;

Game.NUMBER_OF_END_GAME_WATER_PHASES = 2;

Game.UNFLOODABLE_TAGS = ['home', 'water-wheel'];

Game.prototype.on = function (eventName, handler) {
  this._events.subscribe(eventName, handler);
};

Game.prototype.getLevelTitle = function () {
  return this._currentPlayfield.title;
};

Game.prototype.getLevelDescription = function () {
  return this._currentPlayfield.description;
};

Game.prototype.getPlayfieldVersion = function (version) {
  return this._playfields[version];
};

Game.prototype.getDiggableCells = function () {
  var cells = this._currentPlayfield.allCells();
  return _.filter(cells, this.canDigFrom, this);
};

Game.prototype.getDumpableCells = function (digFrom) {
  var cells = this._currentPlayfield.getAdjacentCells(digFrom.x, digFrom.y);
  return _.filter(cells, this._canDigToCell, this);
};

Game.prototype.levelStateToJSON = function (version) {
  if (version == undefined) {
    version = this._playfields.length-1;
  }

  return JSON.stringify(this.getPlayfieldVersion(version));
}

Game.prototype._adjacent = function(digFrom, digTo) {
  var rowDiff, colDiff, differentCells;
  rowDiff = Math.abs(digFrom.y - digTo.y);
  colDiff = Math.abs(digFrom.x - digTo.x);
  differentCells = (rowDiff != 0 || colDiff != 0);
  return (differentCells && rowDiff < 2 && colDiff < 2);
};

Game.prototype.canDigFrom = function(digFrom) {
  var cell = this._currentPlayfield.getCell(digFrom.x, digFrom.y);
  return (cell && cell.isDiggable());
};

// can dumpto
Game.prototype._canDigTo = function(digTo) {
  var cell = this._currentPlayfield.getCell(digTo.x, digTo.y);
  return (cell && this._canDigToCell(cell));
}

Game.prototype._canDigToCell = function(cell) {
  return cell.isDumpable();
};

Game.prototype.dig = function (digFrom, digTo) {
  if (this._gameOver) { return false; }

  if (this._adjacent(digFrom, digTo) && this.canDigFrom(digFrom) && this._canDigTo(digTo)) {
    this._setCurrentPlayfield(this._playfieldAfterDigging(digFrom, digTo));
    this._makeWaterMoves();
    if (this._checkIfPlayerHasWon(this._currentPlayfield)) { this._endGame(true); }
    return true;
  } else {
    return false;
  }
};

Game.prototype.skipTurn = function() {
  if (this._gameOver) { return false; }
  this._makeWaterMoves();
  if (this._checkIfPlayerHasWon(this._currentPlayfield)) { this._endGame(true); }
  return true;
}

Game.prototype.restart = function () {
  this._setCurrentPlayfield(this._playfields[0]);
  this._gameOver = false;
};

Game.prototype._playfieldAfterDigging = function (digFrom, digTo) {
  var newPlayfield = this._currentPlayfield.clone(),
      from = newPlayfield.getCell(digFrom.x, digFrom.y),
      to = newPlayfield.getCell(digTo.x, digTo.y);
  from.elevation -= 1;
  to.elevation += 1;
  newPlayfield.createdBy = Playfield.HUMAN_MOVE;
  return newPlayfield;
};

Game.prototype._makeWaterMoves = function () {
  var i, j;
  for (i = 0; i < this._numberOfWaterTurns; i++) {
    var playfields = this._waterFlowAlgorithm(this._currentPlayfield);

    for (j = 0; j < playfields.length; j++) {
      if (!this._setCurrentPlayfield(playfields[j]))
        return;
    }
  }
};

Game.prototype._setCurrentPlayfield = function (playfield) {
  this._currentPlayfield = playfield;
  this._playfields.push(playfield);
  this._events.emit("playfield-update", {version: this._playfields.length - 1, reason: playfield.createdBy });
  if (this._checkIfPlayerHasLost(playfield)) {
    this._endGame(false);
    return false;
  } else {
    return true;
  }
};

Game.prototype._endGame = function (win) {
  if (!this._gameOver) {
    var i;
    this._gameOver = true;
    this._events.emit("game-over", { win: win });
    //continue water moves after end game to show what happens
    for (i = 0; i < this._numberOfEndGameWaterPhases; i++) {
      this._makeWaterMoves();
    }
  }
};

Game.prototype._checkIfPlayerHasLost = function (playfield) {
  var cells = playfield.getCellsByTags(this._unfloodableTags);
  return _.any(cells, function (cell) {
     return cell.isWet();
  });
};

Game.prototype._checkIfPlayerHasWon = function (playfield) {
  var i, j;
  // To maintain the sense of suspense if there is still rain to come then
  // don't let the player win until it has rained out.

  if (_.has(playfield, "rain") && playfield.rain.length > 0) {
      return false;
  }

  for (i = 0; i < Game.WIN_CHECK_ITERATIONS; i++) {
    var playfields = this._waterFlowAlgorithm(playfield);

    for (j = 0; j < playfields.length; j++) {
      playfield = playfields[j];
      if(this._checkIfPlayerHasLost(playfield)) {
        return false;
      }
    }
  }

  return true;
};

module.exports = Game;
