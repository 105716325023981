var Backbone = require('backbone'),
    jQuery   = require('jquery');

Backbone.$ = jQuery;
window.$ = jQuery;

var MildPeril = require('./mild_peril');

MildPeril.boot({
  levelManifestUrl: '/data/level_manifest.json',
  levelDataUrl: '/data/levels/{levelPath}.txt'
});
