"use strict";

var async        = require('async'),
    $            = require('jquery'),
    _            = require('underscore'),
    EventHandler = require('./eventhandler');

function BrowserUI(game, playfieldSelector) {
  var ui = this;

  this._game = game;
  this._cellElements = [];
  this._$playfield = $(playfieldSelector);
  this._sourceTile = null;
  this._events = new EventHandler();
  this._state = BrowserUI.BEGIN_DIG;

  this._renderQueue = async.queue(function (task, done) { task(done) }, 1);

  this._renderQueue.drain = function() {
    if (ui._state === BrowserUI.GAME_OVER) return;
    ui._state = BrowserUI.BEGIN_DIG;
    ui._highlightDiggableCells();
  };

  this._game.on("playfield-update", function (event) {
    ui._renderQueue.push(function (done) {
      ui.update(event.version);
      setTimeout(done, BrowserUI.ANIMATION_DELAY);
    });
    if (ui._state !== BrowserUI.GAME_OVER) ui._state = BrowserUI.CASCADE;
  });

  this._game.on("game-over", function (event) {
    ui._renderQueue.push(function(done) {
      ui._buildGameStatus(event.win);
      done();
    });
    ui._state = BrowserUI.GAME_OVER;
  });
};

BrowserUI.ANIMATION_DELAY = 500;

BrowserUI.BEGIN_DIG = 1;
BrowserUI.END_DIG   = 2;
BrowserUI.CASCADE   = 3;
BrowserUI.GAME_OVER = 4;

BrowserUI.CELL_WIDTH  = 192;
BrowserUI.CELL_HEIGHT = 224;

BrowserUI.prototype.on = function (eventName, handler) {
  this._events.subscribe(eventName, handler);
};

BrowserUI.prototype._cellClicked = function (tile) {
  var y = tile.y, x = tile.x;

  switch (this._state) {
    case BrowserUI.BEGIN_DIG:
      if (!this._game.canDigFrom(tile)) return;
      this._sourceTile = tile;
      this._highlightDumpableCells();
      this._state = BrowserUI.END_DIG;
      break;

    case BrowserUI.END_DIG:
      if (y === this._sourceTile.y && x === this._sourceTile.x) {
        this._sourceTile = null;
        this._removeHighlights();
        this._state = BrowserUI.BEGIN_DIG;
        return;
      }
      if (!this._game.dig(this._sourceTile, tile)) return;
      this._sourceTile = null;
      this._removeHighlights();
      break;

    case BrowserUI.CASCADE:
    case BrowserUI.GAME_OVER:
      break;
  }
};

BrowserUI.prototype._highlightDiggableCells = function () {
  this._removeHighlights();
  _.forEach(this._game.getDiggableCells(), function (cell) {
    this._cellElements[cell.y][cell.x].addClass('active');
  }, this);
};

BrowserUI.prototype._highlightDumpableCells = function () {
  this._removeHighlights();
  this._cellElements[this._sourceTile.y][this._sourceTile.x].addClass("selected");

  _.forEach(this._game.getDumpableCells(this._sourceTile), function (cell) {
    this._cellElements[cell.y][cell.x].addClass("active").addClass("highlighted");
  }, this);
};

BrowserUI.prototype._removeHighlights = function () {
  this._$playfield.find(".active").removeClass("active");
  this._$playfield.find(".selected").removeClass("selected");
  this._$playfield.find(".highlighted").removeClass("highlighted");
};

BrowserUI.prototype.build = function () {
  var cellRight, cellBottom, maxRight = 0, maxBottom = 0;

  this._$playfield.empty();
  $("#game-status").removeClass("win").removeClass("lose");
  this._buildLevelTitle();

  _.forEach(this._game.getPlayfieldVersion(0).allCells(), function (cell) {
    this._buildCell(cell);

    cellRight = BrowserUI.CELL_WIDTH * (cell.x + 1);
    cellBottom = BrowserUI.CELL_HEIGHT * (cell.y + 1);
    maxRight = _.max([cellRight, maxRight]);
    maxBottom = _.max([cellBottom, maxBottom]);
  }, this);

  this._setPlayfieldSize(maxRight, maxBottom);
  this.update(0);
  this._highlightDiggableCells();
};

BrowserUI.prototype._setPlayfieldSize = function (idealWidth, idealHeight) {
  var heightScaleFactor = ($(window).height() - $("header").height()) / idealHeight;
  var widthScaleFactor = $(window).width() / idealWidth;
  var scaleFactor = _.min([heightScaleFactor, widthScaleFactor]);

  this._$playfield.css({
    "transform": "scale(" + scaleFactor + ")",
    "transform-origin": "0 0",
    "width": (idealWidth * scaleFactor) + "px",
    "height": (idealHeight * scaleFactor) + "px"
  });
};

BrowserUI.prototype._buildLevelTitle = function () {
  $("#level-title").text(this._game.getLevelTitle());

  if (this._game.getLevelDescription()) {
    $("#level-description").html("<p>" + this._game.getLevelDescription() + "</p>");
  } else {
    $("#level-description").html("");
  }
};

BrowserUI.prototype._buildGameStatus = function (win) {
  if(win) {
    $('#game-status .message').text('Verily, the homestead is saved.');
    $('#game-status').attr('class', 'win');
  } else {
    $('#game-status .message').text('Alas, the homestead is regrettably damp.');
    $('#game-status').attr('class', 'lose');
  }
}

BrowserUI.prototype.restart = function () {
  // Clear any pending render tasks. There is no public API to do this without
  // also removing the drain callback.
  this._renderQueue.tasks = [];
  $("#game-status").removeClass("win").removeClass("lose");
  this._state = BrowserUI.BEGIN_DIG;
  this._game.restart();
};

BrowserUI.prototype.skipTurn = function () {
  if (this._state != BrowserUI.CASCADE && this._state != BrowserUI.GAME_OVER) {
    this._game.skipTurn();
  }
};

BrowserUI.prototype._buildCell = function (cell) {
  var cellWidth = BrowserUI.CELL_WIDTH,
      cellHeight = BrowserUI.CELL_HEIGHT,
      ui = this;

  this._cellElements[cell.y] = this._cellElements[cell.y] || [];

  var elem = this._cellElements[cell.y][cell.x] = $(
    '<div class="cell" data-elevation="0" data-depth="0">' +
      '<span class="water"><span class="flow"></span></span>' +
      '<span class="land"></span>' +
      '<span class="tag"></span>' +
      '<span class="rate"></span>' +
    '</div>');

  var left = cellWidth * cell.x,
      top  = cellHeight * cell.y;

  elem.css({left: left + 'px', top: top + 'px'});

  if (cell.isSource() ) {
    elem.find('.rate').text("flow of " + cell.flowRate);
  } else if (cell.isSink()) {
    elem.find('.rate').text("drain of " + cell.flowRate);
  }

  elem.click(function () {
    ui._cellClicked({y: cell.y, x: cell.x})
  });

  this._$playfield.append(elem);
};

BrowserUI.prototype.update = function (version) {
  _.forEach(this._game.getPlayfieldVersion(version).allCells(), function (cell) {
    this._updateCell(cell);
  }, this);

  this._events.emit("updated", {version: version});
};

BrowserUI.prototype._updateCell = function (cell) {
  var el = this._cellElements[cell.y][cell.x];

  el.attr('data-depth', cell.depth);
  el.attr('data-elevation', cell.elevation);
  el.attr('data-height', cell.height());
  el.attr('data-flow-direction', cell.directionName());

  el.addClass(cell.tag);
};

module.exports = BrowserUI;
