"use strict";

var _ = require('underscore');

function Cell (attributes) {
  this.elevation = attributes.elevation || 0;
  this.depth = attributes.depth || 0;
  if (_.has(attributes, "direction"))
    this.direction = attributes.direction;
  else
    this.direction = null;
  this.tag = attributes.tag || null;
  this.x = attributes.x;
  this.y = attributes.y;
  // The ID is used to store cells (or info about cells) in a dictionary as you
  // can't have objects as keys in JS
  this.id = this.x + "," + this.y;

  if (this.isSink() || this.isSource())
  {
    this.flowRate = attributes.flowRate;
    this.flowThisTurn = attributes.flowThisTurn || 0;
  }
}

Cell.prototype.clone = function () {
  return new Cell({
    elevation: this.elevation,
    depth: this.depth,
    direction: this.direction,
    tag: this.tag,
    x: this.x,
    y: this.y,
    id: this.id,
    flowRate: this.flowRate,
    flowThisTurn: this.flowThisTurn
  });
};

// Cardianal Direcitons
// North is -ve Y, East is +ve X
Cell.NORTH = 0;
Cell.EAST = 1;
Cell.SOUTH = 2;
Cell.WEST = 3;

Cell.DIRECTION_TO_SYMBOL = [ "^", ">", "v", "<" ];
Cell.DIRECTION_TO_WORD = [ "up", "right", "down", "left" ];

Cell.UNEDITABLE_TAGS = ['home', 'sink', 'tree', 'water-wheel'];

Cell.prototype.isSink = function () {
  return this.tag == "sink";
}

Cell.prototype.isSource = function () {
  return this.tag == "source";
}

Cell.prototype.height = function () {
  return this.elevation + this.depth;
};

Cell.prototype.isWet = function () {
  return this.depth > 0;
};

Cell.prototype.isDumpable = function () {
  return (this.elevation < 4 && Cell.UNEDITABLE_TAGS.indexOf(this.tag) === -1);
}

Cell.prototype.isDiggable = function () {
  return (this.elevation > 0 && Cell.UNEDITABLE_TAGS.indexOf(this.tag) === -1);
}

// Yes this is un-javascripty :D
Cell.prototype.repr = function () {
  var str = "<Cell: " + this.x + "," + this.y + ":h" + this.height();
  if (this.direction !== null)
    str += ":d" + Cell.DIRECTION_TO_SYMBOL[this.direction];
  return str + " >";

}

Cell.prototype.directionName = function () {
  if (this.direction !== null) {
    return Cell.DIRECTION_TO_WORD[this.direction];
  }
  return null;
};

// This function will behave badly/inconsistently if you pass a value that is
// not in a straight line away from this cell
Cell.prototype.directionTo = function (other) {
  if (other.y < this.y)
    return Cell.NORTH;
  if (other.y > this.y)
    return Cell.SOUTH;
  if (other.x < this.x)
    return Cell.WEST;
  if (other.x > this.x)
    return Cell.EAST;
  throw new Error("directionTo: can't find direction");
}

Cell.idToCoords = function (id) {
  var coords = id.split(',');
  // ~~x forces x to be an integer
  return { x: ~~coords[0], y: ~~coords[1] };
}

module.exports = Cell;
