"use strict";

var Cell      = require('../cell'),
    Playfield = require('../playfield'),
    dijkstra  = require('./dijkstra'),
    _         = require('underscore');

/*
 * Takes a playfield state before the water moves, and flows the water.
 * Returns an array of playfields, representing the states the playfield
 * goes through as the water flows.
 */
var pressure = function (originalPlayfield) {
  var playfield = originalPlayfield.clone(),
      playfields = [];

  if (addWaterToSources(playfield)) {
    playfields.push(playfield);
    playfield = playfield.clone();
  }

  while (step(playfield)) {
    playfields.push(playfield);
    playfield = playfield.clone();
  }

  if (resetSinks(playfield)) {
    playfields.push(playfield);
  }

  return playfields;
};

var addWaterToSources = function (playfield) {
  _.forEach(playfield.allCells(), function (cell) {
    if (cell.isSource()) {
      cell.depth += cell.flowRate;
      playfield.createdBy = Playfield.WATER_SOURCE;
    }
  });

  _.forEach(playfield.rain, function(rain) {
    // If there is rain in the list then we still need to create a new
    // playfield state with the counter going down.
    playfield.createdBy = Playfield.WATER_SOURCE;

    if ( (--rain.when) <= 0) {
      rain.turns--;
      // Rain can last more than one turn too
      var cell = playfield.getCell(rain.x, rain.y);
      if (cell) {
        cell.depth += rain.flowRate;
      } else {
        console.log("IT HAD NO CELL TO RAIN ON")
      }
    }
  });

  // We remove rain from the list once it's done all it needs too.
  playfield.rain = _.reject(playfield.rain, function (rain) {
    return rain.when <= 0 && rain.turns <= 0;
  })

  return playfield.createdBy == Playfield.WATER_SOURCE;
};

var step = function(playfield) {
  playfield.createdBy = Playfield.WATER_FLOW;
  return _.any(cellEvaluationOrder(playfield), function (cell) {
    return flow(playfield, cell);
  });
};

var resetSinks = function (playfield) {
  _.forEach(playfield.allCells(), function(c) {
    if (c.isSink() && c.flowThisTurn > 0) {
      c.flowThisTurn = 0;
      playfield.createdBy = Playfield.SINK_EMPTY;
    }
  });
  return playfield.createdBy == Playfield.SINK_EMPTY;
}

// What order should we loop over the cells. Will be called once for each
// height present on the map
var cellEvaluationOrder = function(playfield) {
  var minSinkDistance =  function(cell) {
    var minLength;
    if (sinks.length === 0) { return 0; }
    minLength = _.min(_.collect(sinks, distToSinkForCell(cell)));
    if (minLength === Infinity) { return 0; } 
    return minLength;
  };


  var sinks = playfield.sinkCells(),
      distsForSink = function(sink) { return dijkstra.findPathDistancesFrom(sink, playfield); },
      distToSinkForCell = function(sink) { return function(cell) { return distsForSink(sink)[cell.id]; } },
      gridOrder = function(c) { return ((c.y * 100) + c.x); },
      cellOrdering = function (c) { 
        return  (c.height()         *       100000000 +
                 c.elevation        *         1000000 +
                 minSinkDistance(c) *            1000 +
                 gridOrder(c)       *               1 )  * -1 // to invert order
      };

  return _.sortBy(playfield.wetCells(), cellOrdering);
}

var orderByHeightThenElevation = function(possibles) {
  return _.sortBy(possibles, function(x) {
    // TODO: height being a function but elevation being a property is going to
    // get confusing
    return -x.height() * 100 - x.elevation;
  });
}


var flow = function(playfield, source) {
  var f, possibles = playfield.allCells();

  var filteringFunctions = [
    sinkHasCapacity,
    reachable,
    twoHeightDifference,
  ];
  var orderingFunctions = [
    lowestHeight,
    lowestElevation,
    nearest,
    flowDirection,
    maxY,
    maxX
  ];

  // We want to run *all the filters* all the time, but only run the order to
  // tie break if we have more than one element left over
  for (f = 0; possibles.length > 0 && f < filteringFunctions.length; f += 1) {
    possibles = filteringFunctions[f](source, possibles, playfield);
    // Helpful debugging: list number of cells left after each filter function
    //console.log(source.id, filteringFunctions[f].displayName, "(" + possibles.length +  ")")
  }

  for (f = 0; possibles.length > 1 && f < orderingFunctions.length; f += 1) {
    possibles = orderingFunctions[f](source, possibles, playfield);
    //console.log(source.id, orderingFunctions[f].displayName, "(" + possibles.length +  ")")
  }


  if (possibles.length == 1 && possibles[0].id !== source.id) {
    //console.log(source.id, "Flowing to", possibles[0].id);
    source.depth -= 1;
    if (possibles[0].isSink()) {
      possibles[0].flowThisTurn += 1;
    }
    else {
      possibles[0].depth += 1;
    }
    setFlowDirectionAlongPath(source, possibles[0], playfield);
    return true;
  } else if (possibles.length > 1) {
    throw new Error("Filtering found multiple possibilities!");
  }
  return false;
}

var setFlowDirectionAlongPath = function (source, dest, playfield) {
  var path, prev, curr;

  path = dijkstra.findPathTo(source, [dest], playfield);
  prev = source;
  while (path.length > 0) {
    curr = path.shift();
    curr.direction = prev.direction = prev.directionTo(curr);
    prev = curr;
  }
}

// Remove any sinks which are already 'full' this turn
var sinkHasCapacity = function(source, possibles) {
  return _.select(possibles, function(possible) {
    return !possible.isSink() || possible.flowThisTurn < possible.flowRate;
  });
}
sinkHasCapacity.displayName = "sinkHasCapacity";

var reachable = function(source, possibles) {
  // What are the possible cells that we could move water in to? Tiles that are
  // already wet, or next to a cell that is wet.
  //
  // This might be a prime candidate for caching as this information doesn't
  // change very often. But it's not *that* expensive to calcualte at the size
  // of maps we are talking about
  var results = [],
      cell,
      neighbours,
      queue = [source];

  var iter = 0;
  while (queue.length > 0) {
    cell = queue.shift();
    // Find neighbours in cardinal directions (but not in diagonals)
    neighbours = _.select(possibles, function (cand) {
      return (cand.x == cell.x && ( cand.y == cell.y - 1 || cand.y == cell.y + 1 ) ) ||
             (cand.y == cell.y && ( cand.x == cell.x - 1 || cand.x == cell.x + 1 ) )
    });

    _.forEach( neighbours, function (neighbour) {
      // Already visited?
      if (_.include(results, neighbour))
        return;

      results.push(neighbour);
      if (neighbour.isWet())
        queue.push(neighbour);
    });
  }

  return results;
}
reachable.displayName = "reachable";

var twoHeightDifference = function(source, possibles) {
  return _.select(possibles, function(possible) {
    return source.height() - possible.height() >= 2;
  });
}
twoHeightDifference.displayName = "twoHeightDifference";

var lowestHeight = function(source, possibles) {
  var cellHeight = function(possible) { return possible.height(); },
      byHeight = _.groupBy(possibles, cellHeight);

  return byHeight[_.min(_.keys(byHeight))];
}
lowestHeight.displayName = "lowestHeight"

var lowestElevation = function(source, possibles) {
  var cellElevation = function(possible) { return possible.elevation; },
      byElevation = _.groupBy(possibles, cellElevation);

  return byElevation[_.min(_.keys(byElevation))];
}
lowestElevation.displayName = "lowestElevation"

var nearest = function(source, possibles, playfield) {

  // We want to calculate the path along any wet cell, or next to a wet cell,
  // as our target cell is as likely as not to be dry. The default path
  // finder only looks at wet cells, so this function is passed in and changes
  // that behaviour
  var adjaceneyFunction = function(current, playfield) {
    var cell = playfield.getCellByID(current);
    if (!cell.isWet()) {
      // If we're not wet then we have no where else we can go (we came out of
      // a wet tile)
      return [];
    }

    var neighbours = playfield.getAdjacentCells(cell.x, cell.y, false);
    return _.pluck(neighbours, "id");
  };

  var pathCostsFromSource = dijkstra.findPathDistancesFrom(source, playfield, adjaceneyFunction);

  var byPathDistance = _.groupBy(possibles, function (possible) {
    return pathCostsFromSource[possible.id];
  });
  // Make sure we sort numerically, not asciibetically
  return byPathDistance[_.min(_.keys(byPathDistance), function (str) { return parseInt(str, 10)} )];
}
nearest.displayName = "nearest";

var flowDirection = function(source, possibles, playfield) {
  // This might need changing as in play testing we think we were looking at
  // the flow path from flooded cell, but that is a lot harder to work out
  //
  // TODO: Check our levels are still solvable with this.

  var scoring = [
    [ [ 0,-1], Cell.SOUTH ],
    [ [ 1, 0], Cell.WEST ],
    [ [ 0, 1], Cell.NORTH ],
    [ [-1, 0], Cell.EAST ]
  ]
  var scored = _.groupBy(possibles, function(possible) {
    var score = 0;
    _.each(scoring, function(condition) {
      var x = possible.x + condition[0][0],
          y = possible.y + condition[0][1],
          cell = playfield.getCell(x, y);

      if (cell && cell.direction === condition[1]) {
          score++;
      }
    });
    return score;
  });

  return scored[_.max(_.keys(scored))];

}
flowDirection.displayName = "flowDirection";

var maxY = function (source, possibles) {
  var byY = _.groupBy(possibles, function(possible) {
    return possible.y;
  });
  return byY[_.max(_.keys(byY))];
}
maxY.displayName = "maxY";

var maxX = function (source, possibles) {
  var byX = _.groupBy(possibles, function(possible) {
    return possible.x;
  });
  return byX[_.max(_.keys(byX))];
}
maxX.displayName = "maxX";

module.exports = pressure;
