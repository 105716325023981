'use strict';

var $ = require('jquery');

function Debugger(selector, game, ui) {
  var d = this;
  this.elements = [];
  this.ui = ui;
  this.$list = $(selector);

  game.on("playfield-update", function (event) { d.add(event.version, event.reason); });
  game.on("game-over", function (event) {
    if (event.win) {
      d.addText('Game over: YOU WON!');
    } else {
      d.addText('Game over: YOU LOST!');
    }
  });

  ui.on("updated", function (event) { d.highlight(event.version); });
  this.reset();
  this.add(0);
}
Debugger.prototype.add = function (version, reason) {
  var d = this;
  this.elements[version] = $("<li></li>").
    addClass("state").
    text("Step " + version + (reason ? ( " " + reason) : "") ).
    click(function () { d.ui.update(version); }).
    prependTo(this.$list);
};
Debugger.prototype.addText = function (text) {
  $("<li></li>").
    addClass("text").
    text(text).
    prependTo(this.$list);
};
Debugger.prototype.highlight = function (version) {
  for (var i = 0; i < this.elements.length; i += 1) {
    this.elements[i].toggleClass("selected", version == i);
  }
};
Debugger.prototype.reset = function () {
  this.elements = [];
  this.$list.find('li').remove();
};

module.exports = Debugger;
